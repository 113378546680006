import React from 'react';
import './index.css';
import { Routes, Route, Navigate } from 'react-router-dom';

import * as Pg from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Pg.Countdown />} />
      <Route path="*" element={<Pg.NotFound />} />
    </Routes>
  );
}

export default App;