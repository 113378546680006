// @ts-nocheck
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../index.css";
import { LoadingContext, UserContext } from "../../utils/context";

export const Countdown = () => {
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get("src");

    const [timeNow, setTimeNow] = React.useState(new Date());
    const [now, setToday] = React.useState({});
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimeNow(new Date());
        }, 10);
        totalCountdown(timePairs, new Date());
    }, []);

    React.useEffect(() => {
        const format = timeNow.toLocaleTimeString().split(" ");
        const time = format[0].split(":");
        const hours = `${time[0].padStart(2, "0")}:${time[1]}:${time[2]} ${
            format[1]
        }`;
        setToday({
            month: timeNow.getMonth() + 1,
            day: timeNow.getDate(),
            year: timeNow.getFullYear(),
            hours: hours,
        });
    }, [timeNow]);

    function countdown(timePairs: Array<any>, timeNow: number) {
        const timePairsFiltered = timePairs.filter((timePair) => {
            return timeNow < stringToTime(timePair.end);
        });

        // console.log(timePairsFiltered);

        if (timePairsFiltered.length > 0) {
            const withinTimePair =
                timeNow >= stringToTime(timePairsFiltered[0].start);
            const firstPair = withinTimePair
                ? stringToTime(timePairsFiltered[0].end) - timeNow
                : 0;
            const leftTimePairs = withinTimePair
                ? timePairsFiltered.slice(1)
                : timePairsFiltered;
            const totalMS =
                firstPair +
                leftTimePairs.reduce((acc, curr) => {
                    return (
                        acc + stringToTime(curr.end) - stringToTime(curr.start)
                    );
                }, 0);

            return totalMS;
        }
        return 0;
    }

    function dailyCountdown(
        schedule: Array<any>,
        timeNow: number,
        timePairs: Array<any>
    ) {
        const currentHMS = new Date(timeNow);
        const currentH = currentHMS.getHours();
        const currentM = currentHMS.getMinutes();
        const currentS = currentHMS.getSeconds();
        const hourString = `${currentH}:${currentM}:${currentS}`;
        const scheduleFiltered = schedule.filter((timePair) => {
            return (
                hourStringToTime(hourString) < hourStringToTime(timePair.end)
            );
        });
        const timePairsFiltered = timePairs.filter((timePair) => {
            return timeNow < stringToTime(timePair.end);
        });

        if (timePairsFiltered.length > 0) {
            const withinTimePair =
                timeNow >= stringToTime(timePairsFiltered[0].start);

            if (scheduleFiltered.length > 0 && withinTimePair) {
                const withinTimePair =
                    hourStringToTime(hourString) >=
                    hourStringToTime(scheduleFiltered[0].start);
                const firstPair = withinTimePair
                    ? hourStringToTime(scheduleFiltered[0].end) -
                      hourStringToTime(hourString)
                    : 0;
                if (withinTimePair) {
                    return [scheduleFiltered[0].name, firstPair];
                }
            }

            const schoolStartsIn =
                stringToTime(timePairsFiltered[0].start) - timeNow;
            return ["School starts in", schoolStartsIn];
        }
        return [
            "Time since break started",
            timeNow - stringToTime(timePairs[timePairs.length - 1].end),
        ];
    }

    function totalCountdown(timePairs: Array<any>, timeNow: number) {
        const timePairsFiltered = timePairs.filter((timePair) => {
            return timeNow < stringToTime(timePair.end);
        });

        if (timePairsFiltered.length > 0) {
            const lastSchoolDay = stringToTime(
                timePairsFiltered[timePairsFiltered.length - 1].end
            );
            const leftTime = lastSchoolDay - timeNow;
            return leftTime;
        }
        return 0;
    }

    function stringToTime(dateString: string) {
        const [date, time] = dateString.split(" ");
        const [month, day, year] = date.split("/");
        const [hours, minutes, seconds] = time.split(":");

        const dateObject = new Date(
            Number(year),
            Number(month) - 1,
            Number(day),
            Number(hours),
            Number(minutes),
            Number(seconds)
        );
        return dateObject.getTime();
    }

    function hourStringToTime(time: string) {
        const [hours, minutes, seconds] = time.split(":");

        const dateObject = new Date(1970, 0, 1);
        dateObject.setHours(Number(hours));
        dateObject.setMinutes(Number(minutes));
        dateObject.setSeconds(Number(seconds));
        return dateObject.getTime();
    }

    function formatMilliseconds(
        milliseconds: number,
        noDays?: boolean = false
    ) {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (noDays) {
            return [0, hours, minutes % 60, seconds % 60];
        }
        return [days, hours % 24, minutes % 60, seconds % 60];
    }

    function hoursLeftOfDay(timePairs: Array<any>, timeNow: number) {
        const timePairsFiltered = timePairs.filter((timePair) => {
            return timeNow < stringToTime(timePair.end);
        });

        if (timePairsFiltered.length > 0) {
            const withinTimePair =
                timeNow >= stringToTime(timePairsFiltered[0].start);
            if (withinTimePair) {
                return stringToTime(timePairsFiltered[0].end) - timeNow;
            }
        }
        return 0;
    }

    function copyToClipboard(text) {
        const elem = document.createElement("textarea");
        elem.value = text;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
    }

    function readableDayString() {
        return `${days[timeNow.getDay()]} ${
            months[timeNow.getMonth()]
        } ${timeNow.getDate()}, ${timeNow.getFullYear()}`;
    }

    function withinTimeWindow(timePairs: Array<any>, timeNow: number) {
        const timePairsFiltered = timePairs.filter((timePair) => {
            return timeNow < stringToTime(timePair.end);
        });

        if (timePairsFiltered.length > 0) {
            const withinTimePair =
                timeNow >= stringToTime(timePairsFiltered[0].start);
            return withinTimePair;
        }
    }

    // ELEMENTS

    function CountdownElement(props: {
        label: string;
        timearray: Array<any>;
        noDays?: boolean;
    }) {
        const days = props.timearray[0];
        const hours = props.timearray[1];
        const minutes = props.timearray[2];
        const seconds = props.timearray[3];

        return (
            <div className="shrink-0 grid h-32 grid-cols-8 col-span-4 gap-2 p-2 bg-gray-800 rounded-md">
                <h1 className="whitespace-nowrap w-full col-span-8 font-bold text-center text-gray-400">
                    {props.label}
                </h1>
                <div
                    className={`col-span-1 ${!props.noDays ? "hidden" : ""}`}
                />
                <div className={`col-span-2 ${props.noDays ? "hidden" : ""}`}>
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {days.toString()}
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        {days !== 1 ? "Days" : "Day"}
                    </h1>
                </div>
                <div className="col-span-2">
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {hours.toString().padStart(2, "0")}
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        {hours !== 1 ? "Hours" : "Hour"}
                    </h1>
                </div>
                <div className="col-span-2">
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {minutes.toString().padStart(2, "0")}
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        {minutes !== 1 ? "Minutes" : "Minute"}
                    </h1>
                </div>
                <div className="col-span-2">
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {seconds.toString().padStart(2, "0")}
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        {seconds !== 1 ? "Seconds" : "Second"}
                    </h1>
                </div>
                <div
                    className={`col-span-1 ${!props.noDays ? "hidden" : ""}`}
                />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center h-auto min-h-screen py-10 bg-gray-900">
            <div className="grid items-center xl:grid-cols-12 md:grid-cols-8 grid-cols-4 mx-auto px-5 w-full lg:w-[960px] xl:w-[1280px] gap-7">
                <div className="md:col-span-8 shrink-0 flex flex-col justify-center h-32 col-span-4 p-2 bg-gray-800 rounded-md">
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        Today is
                    </h1>
                    <h1 className="sm:text-4xl whitespace-nowrap w-full font-mono text-xl font-bold text-center text-white">
                        {readableDayString()}
                    </h1>
                </div>
                <div className="shrink-0 flex flex-col justify-center h-32 col-span-4 p-2 bg-gray-800 rounded-md">
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        Time
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {now.hours}
                    </h1>
                </div>
                <div className="shrink-0 grid h-32 grid-cols-1 col-span-4 gap-2 p-2 bg-gray-800 rounded-md">
                    <h1 className="whitespace-nowrap w-full col-span-1 font-bold text-center text-gray-400">
                        The{" "}
                        {withinTimeWindow(timePairs, timeNow)
                            ? "current"
                            : "next"}{" "}
                        school day is a
                        {timePairs.filter((timePair) => {
                            return (
                                timeNow.getTime() < stringToTime(timePair.end)
                            );
                        })[0]?.rotation === "A"
                            ? "n"
                            : ""}
                    </h1>
                    <div className="col-span-2">
                        <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                            {timePairs.filter((timePair) => {
                                return (
                                    timeNow.getTime() <
                                    stringToTime(timePair.end)
                                );
                            })[0]?.rotation || "SUMMER BREAK"}
                        </h1>
                        <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                            Day
                        </h1>
                    </div>
                </div>
                <CountdownElement
                    label="Today's school ends in"
                    timearray={formatMilliseconds(
                        hoursLeftOfDay(timePairs, timeNow.getTime())
                    )}
                    noDays
                />
                <CountdownElement
                    label={
                        dailyCountdown(
                            fullDaySchedule,
                            timeNow.getTime(),
                            timePairs
                        )[0]
                    }
                    timearray={formatMilliseconds(
                        dailyCountdown(
                            fullDaySchedule,
                            timeNow.getTime(),
                            timePairs
                        )[1]
                    )}
                />
                <CountdownElement
                    label="Time left in school before summer"
                    timearray={formatMilliseconds(
                        countdown(timePairs, timeNow.getTime()),
                        true
                    )}
                    noDays
                />
                <CountdownElement
                    label="Summer break starts in"
                    timearray={formatMilliseconds(
                        totalCountdown(timePairs, timeNow.getTime())
                    )}
                />
                <div className="shrink-0 flex flex-col justify-center h-32 col-span-2 p-2 bg-gray-800 rounded-md">
                    <h1 className="w-full font-bold text-center text-gray-400">
                        Full days left
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {
                            timePairs.filter((timePair) => {
                                return (
                                    timeNow.getTime() <
                                        stringToTime(timePair.end) &&
                                    timePair.type === "full"
                                );
                            }).length
                        }
                    </h1>
                </div>
                <div className="shrink-0 flex flex-col justify-center h-32 col-span-2 p-2 bg-gray-800 rounded-md">
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        Half days left
                    </h1>
                    <h1 className="whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white">
                        {
                            timePairs.filter((timePair) => {
                                return (
                                    timeNow.getTime() <
                                        stringToTime(timePair.end) &&
                                    timePair.type === "half"
                                );
                            }).length
                        }
                    </h1>
                </div>
                {/* <div className="shrink-0 flex flex-col justify-center h-32 col-span-4 p-2 bg-gray-800 rounded-md">
                    <h1 className="whitespace-nowrap w-full font-bold text-center text-gray-400">
                        Unix epoch timestamp
                    </h1>
                    <h1 className="hover:cursor-pointer whitespace-nowrap w-full font-mono text-4xl font-bold text-center text-white" onClick={copyToClipboard(timeNow.getTime())}>
                        {timeNow.getTime().toString()}
                    </h1>
                </div> */}
            </div>
            <div className="flex items-center justify-center w-full gap-5 my-5 font-bold text-white">
                <button
                    onClick={() => {
                        window.location.href = src;
                    }}
                    className={`${
                        !src ? "hidden" : "px-3 py-1"
                    } border-white border rounded-lg`}
                >
                    Back
                </button>
                Made by <a href="https://github.com/paridax">Trevor</a>
            </div>
        </div>
    );
};

const timePairs = [
    {
        start: "04/22/2024 7:45:00",
        end: "04/22/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "04/23/2024 7:45:00",
        end: "04/23/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "04/24/2024 7:45:00",
        end: "04/24/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "04/25/2024 7:45:00",
        end: "04/25/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "04/26/2024 7:45:00",
        end: "04/26/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "04/29/2024 7:45:00",
        end: "04/29/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "04/30/2024 7:45:00",
        end: "04/30/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "05/01/2024 7:45:00",
        end: "05/01/2024 12:00:00",
        type: "half",
        rotation: "S" 
    },
    {
        start: "05/02/2024 7:45:00",
        end: "05/02/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "05/03/2024 7:45:00",
        end: "05/03/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "05/08/2024 7:45:00",
        end: "05/08/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "05/09/2024 7:45:00",
        end: "05/09/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "05/10/2024 7:45:00",
        end: "05/10/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "05/13/2024 7:45:00",
        end: "05/13/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "05/14/2024 7:45:00",
        end: "05/14/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "05/15/2024 7:45:00",
        end: "05/15/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "05/16/2024 7:45:00",
        end: "05/16/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "05/17/2024 7:45:00",
        end: "05/17/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "05/20/2024 7:45:00",
        end: "05/20/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "05/21/2024 7:45:00",
        end: "05/21/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "05/22/2024 7:45:00",
        end: "05/22/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "05/23/2024 7:45:00",
        end: "05/23/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "05/28/2024 7:45:00",
        end: "05/28/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "05/29/2024 7:45:00",
        end: "05/29/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "05/30/2024 7:45:00",
        end: "05/30/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "05/31/2024 7:45:00",
        end: "05/31/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "06/03/2024 7:45:00",
        end: "06/03/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "06/04/2024 7:45:00",
        end: "06/04/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "06/05/2024 7:45:00",
        end: "06/05/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "06/06/2024 7:45:00",
        end: "06/06/2024 14:45:00",
        type: "full",
        rotation: "A" 
    },
    {
        start: "06/07/2024 7:45:00",
        end: "06/07/2024 14:45:00",
        type: "full",
        rotation: "B" 
    },
    {
        start: "06/10/2024 7:45:00",
        end: "06/10/2024 14:45:00",
        type: "full",
        rotation: "C" 
    },
    {
        start: "06/11/2024 7:45:00",
        end: "06/11/2024 14:45:00",
        type: "full",
        rotation: "D" 
    },
    {
        start: "06/12/2024 7:45:00",
        end: "06/12/2024 12:00:00",
        type: "half",
        rotation: "F" 
    },
    {
        start: "06/13/2024 7:45:00",
        end: "06/13/2024 12:00:00",
        type: "half",
        rotation: "E" 
    },
    {
        start: "06/14/2024 7:45:00",
        end: "06/14/2024 12:00:00",
        type: "half",
        rotation: "E" 
    },
    {
        start: "06/17/2024 7:45:00",
        end: "06/17/2024 12:00:00",
        type: "half",
        rotation: "F" 
    },
    {
        start: "06/18/2024 7:45:00",
        end: "06/18/2024 12:00:00",
        type: "half",
        rotation: "F" 
    },
];

const fullDaySchedule = [
    { start: "7:45:00", end: "8:41:00", name: "Session 1", session: 1 },
    {
        start: "8:41:00",
        end: "8:45:00",
        name: "Passing Time",
        session: 2,
        type: "next",
    },
    { start: "8:45:00", end: "9:39:00", name: "Session 2", session: 2 },
    {
        start: "9:39:00",
        end: "9:43:00",
        name: "Passing Time",
        session: 3,
        type: "next",
    },
    { start: "9:43:00", end: "10:17:00", name: "Recitation" },
    {
        start: "10:17:00",
        end: "10:21:00",
        name: "Passing Time",
        session: 3,
        type: "next",
    },
    { start: "10:21:00", end: "11:15:00", name: "Session 3", session: 3 },
    {
        start: "11:15:00",
        end: "11:51:00",
        name: "Lunch",
        session: 4,
        type: "next",
    },
    {
        start: "11:51:00",
        end: "11:55:00",
        name: "Passing Time",
        session: 4,
        type: "next",
    },
    {
        start: "11:55:00",
        end: "12:51:00",
        name: "Session 4",
        session: 4,
    },
    {
        start: "12:51:00",
        end: "12:55:00",
        name: "Passing Time",
        session: 5,
        type: "next",
    },
    { start: "12:55:00", end: "13:49:00", name: "Session 5", session: 5 },
    {
        start: "13:47:00",
        end: "13:51:00",
        name: "Passing Time",
        session: 6,
        type: "next",
    },
    { start: "13:51:00", end: "14:45:00", name: "Session 6", session: 6 },
];

const periods = {
    A: {
        1: 1,
        2: 2,
        3: 3,
        4: 5,
        5: 6,
        6: 7,
    },
    B: {
        1: 2,
        2: 3,
        3: 4,
        4: 6,
        5: 7,
        6: 8,
    },
    C: {
        1: 3,
        2: 4,
        3: 1,
        4: 7,
        5: 8,
        6: 5,
    },
    D: {
        1: 4,
        2: 1,
        3: 2,
        4: 8,
        5: 5,
        6: 6,
    },
};
